<template>
  <b-card>
    <form-component :file="file" @onSubmit="onSubmit" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/downloadable-files/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { create } from '@/http/downloadable-files';

import { PRIORITY_LEVELS } from '@/constants/enums/priority';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      file: {
        name: '',
        tag: null,
        file: null,
        url: '',
        is_public: false,
        priority: PRIORITY_LEVELS.CRITICAL,
      },
    };
  },

  methods: {
    async onSubmit(form) {
      const formData = new FormData();

      formData.append('lang', form.lang);
      formData.append('name', form.name);
      formData.append('file', form.file);
      formData.append('tag_id', form.tag.id);
      formData.append('priority', form.priority);
      formData.append('is_public', form.is_public ? 1 : 0);

      try {
        await create(formData);

        this.showSuccessNotification('Dane zapisane', 'Plik został dodany.');
        this.$router.push({ name: 'downloadable-files-index' });
      } catch (error) {
        const message =
          error.response.data.message ?? 'Wystąpił błąd podczas dodawania pliku. Skontaktuj się ze swoim developerem.';
        this.showErrorNotification('Problem z zapisaniem danych', message);
      }
    },
  },
};
</script>
